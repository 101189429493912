<template>
  <div class="main">
    <div class="banner"></div>
    <div class="nav">
      <div class="content">
        <div class="title">产品与方案中心</div>
        <div class="subtitle">PRODUCT AND SOLUTION CENTER</div>
        <div class="nav-list">
          <div
            class="nav-item"
            v-for="(i, index) in productList"
            :key="i.id"
            @click="toId(i.did, index)"
            :class="isActive == index ? 'active' : ''"
          >
            {{ i.title }}
          </div>
        </div>
      </div>
    </div>
    <!-- 产品列表 -->
    <div class="pro">
      <div class="content" v-for="product in productList" :key="product.id">
        <div class="title" :id="product.did">
          <div
            class="icon"
            :style="{ background: 'url(' + product.img + ')' }"
          ></div>
          <div>{{ product.title }}</div>
        </div>
        <div :class="product.row ? product.row : 'row-2'">
          <!-- 产品项列表 -->
          <template v-for="item in product.list">
            <div
              class="item"
              :class="item.class"
              :style="{ background: 'url(' + item.icon + ')' }"
              :key="item.name"
            >
              <div class="title1">
                <div class="icon">
                  <img :src="item.bgurl" alt="" />
                </div>
                <div class="text">
                  <div class="bold">{{ item.name }}</div>
                  <div>{{ item.subTitle }}</div>
                </div>
              </div>
              <a :href="'/ProductList'+item.url">
                <div class="btn">了解详情</div>
              </a>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import product from "../utils/product.js";
export default {
  data() {
    return {
      productList: product.ProductList,
      isActive: "",
    };
  },
  methods: {
    toId(e, index) {
      this.isActive = index;
      document.getElementById(e).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    },
  },
  mounted() {
    let id = this.$route.query.id;

    if (id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 450px;
  background: url("../assets/img/product/banner_list.jpg");
  background-size: 2560px 450px;
  background-position: center;
}
.nav {
  padding: 30px 0;
  text-align: center;
  .title {
    height: 110px;
    background: url("../assets/img/product/product_title.png") no-repeat;
    background-size: 106px 110px;
    background-position: center;
    font-size: 40px;
    line-height: 118px;
    color: #000000;
    font-weight: bold;
  }
  .subtitle {
    font-size: 14px;
    color: #818181;
    margin-bottom: 50px;
  }
  .nav-list {
    display: flex;
    justify-content: space-between;
    .nav-item {
      cursor: pointer;
      width: 162px;
      height: 40px;
      background-color: #ffffff;
      border-radius: 20px;
      border: solid 1px #e5e5e5;
      font-size: 16px;
      line-height: 40px;
      color: #515151;
    }
    .active {
      background: url("../assets/img/product/product_list_link_bg.png");
      color: #fff;
    }
  }
}
.pro {
  background-color: #f5f5f5;
  padding-top: 55px;
  padding-bottom: 30px;
  .content {
    margin-bottom: 70px;
    .title {
      display: flex;
      margin-bottom: 32px;
      .icon {
        width: 45px;
        height: 40px;
        margin-right: 10px;
      }
      height: 40px;
      font-size: 22px;
      line-height: 40px;
      color: #be9561;
    }
    .item {
      position: relative;
      background: #ffffff;
      background-size: 100%;
      background-repeat: no-repeat;
      border-radius: 3px;
      top: 0;
      transition:top 0.15s;
      .title1 {
        display: flex;
        .icon {
          width: 90px;
          height: 90px;
          margin: 20px 10px;
        }
        .text {
          margin-top: 40px;
          font-size: 14px;
          color: #b2b1b2;
          .bold {
            font-size: 22px;
            color: #111111;
            margin-bottom: 16px;
          }
        }
      }
      .btn {
        position: absolute;
        width: 111px;
        height: 34px;
        background: url("../assets/img/product/product_look.png");
        line-height: 34px;
        text-align: center;
        font-size: 16px;
        color: #1b65fb;
        right: 20px;
        bottom: 20px;
        &:hover {
          background: url("../assets/img/product/product_look_hover.png");
          color: #ffffff;
        }
      }
      &:hover {
        top: -10px;
        box-shadow: 0 0 0 1px #e8caa3;        
      }
    }
  }
}
.row-1,
.row-2,
.row-3 {
  display: grid;
  grid-template-columns: repeat(3, 400px);
  grid-gap: 30px;
}
.row-1 {
  grid-template-rows: repeat(1, 220px);
  grid-template-columns: repeat(1, 1260px);
}
.row-2 {
  grid-template-rows: repeat(2, 220px);
}
.row-3 {
  grid-template-rows: repeat(3, 220px);
}
.span-2 {
  grid-column-start: span 2;
}
</style>